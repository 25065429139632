<template>   
<footer class="main-footer">
  <div class="nav-link" data-widget="pushmenu" id="sidebar-overlay"></div>
    <strong>Copyright &copy; 2014-2021 <a href="">FineSoft Technologies</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.1.0
    </div>
  </footer>
   
</template>
<script>
    export default {
        data() {
            return {
                message: 'Dashboard',
                "Url": "https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg"   
            }
        }
   };
</script>