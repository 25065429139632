<template>
<div id="app">
  <a class="barnew" data-widget="pushmenu" role="button"><i class="fas fa-bars"></i></a>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    
    <a href="" class="brand-link"> <span class="brand-text font-weight-light">{{company_name}}</span>    
    </a>
    
    <div class="sidebar">
      <nav class="mt-2">    
        <div v-if="!isMobile()">
  <desktop>
    
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
<router-link to="/profile" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('27')" class="nav-item menu-open"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-tachometer-alt"></i> Dashboard </a> 
  </li>
</router-link>    
           
<router-link to="/rolelist" @click.prevent="toggleMenu">
<li v-if="this.action.includes('1')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Role Management </a> 
</li>   
</router-link>

<router-link to="/stafflist" @click.prevent="toggleMenu">           
  <li v-if="this.action.includes('2')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Staff </a> 
  </li>
</router-link>  
 
<router-link to="/companylist" v-if="this.company_id == '1'" @click.prevent="toggleMenu">          
  <li class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-users"></i> Manage Company </a> 
  </li>
</router-link>

<router-link to="/clientlist" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('3')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-users"></i> Customers </a> 
  </li>
</router-link>          

<router-link to="/neworder" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('4')" class="nav-item">
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> New Order </a> 
  </li>
</router-link>
          
<router-link to="/pendingorder" @click.prevent="toggleMenu" v-if="this.app_type != '0'">          
  <li v-if="this.action.includes('5')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> Pending Orders </a> 
  </li>
</router-link>          

<router-link to="/completeorder" @click.prevent="toggleMenu" v-if="this.app_type != '0'">          
  <li v-if="this.action.includes('6')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> Delivered Orders </a> 
  </li>
</router-link>          

<router-link to="/orderhistory" @click.prevent="toggleMenu">          
<li v-if="this.action.includes('7')" class="nav-item"> 
  <a class="nav-link"> 
  <i class="nav-icon fas fa-list-alt"></i> All Orders </a> 
</li>
</router-link>

<router-link to="/servicelist" @click.prevent="toggleMenu">        
  <li v-if="this.action.includes('8')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Services </a>
  </li> 
</router-link>

<router-link to="/extrachargelist" @click.prevent="toggleMenu">
  <li v-if="this.action.includes('9')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Extra Charges </a> 
  </li>
</router-link>

<router-link to="/changepassword" @click.prevent="toggleMenu">
  <li v-if="this.action.includes('10')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-unlock-alt"></i> Change Password </a> 
  </li> 
</router-link>  
<li class="nav-item"> <a class="nav-link pon" @click.prevent="logOut"> <i class="fas fa-sign-out-alt"></i> LogOut </a> </li>
</ul>
  </desktop>
</div>
<div v-else>
  <mobile>
    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        
<router-link to="/profile" data-widget="pushmenu" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('27')" class="nav-item menu-open"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-tachometer-alt"></i> Dashboard </a> 
  </li>
</router-link>    
           
<router-link to="/rolelist" data-widget="pushmenu" @click.prevent="toggleMenu">
<li v-if="this.action.includes('1')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Role Management </a> 
</li>   
</router-link>

<router-link to="/stafflist" data-widget="pushmenu" @click.prevent="toggleMenu">           
  <li v-if="this.action.includes('2')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Staff </a> 
  </li>
</router-link>  
          
<router-link to="/clientlist" data-widget="pushmenu" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('3')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-users"></i> Customers </a> 
  </li>
</router-link>          

<router-link to="/neworder" data-widget="pushmenu" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('4')" class="nav-item">
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> New Order </a> 
  </li>
</router-link>
          
<router-link to="/pendingorder" data-widget="pushmenu" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('5')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> Pending Orders </a> 
  </li>
</router-link>          

<router-link to="/completeorder" data-widget="pushmenu" @click.prevent="toggleMenu">          
  <li v-if="this.action.includes('6')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list"></i> Delivered Orders </a> 
  </li>
</router-link>          

<router-link to="/orderhistory" data-widget="pushmenu" @click.prevent="toggleMenu">          
<li v-if="this.action.includes('7')" class="nav-item"> 
  <a class="nav-link"> 
  <i class="nav-icon fas fa-list-alt"></i> All Orders </a> 
</li>
</router-link>

<router-link to="/servicelist" data-widget="pushmenu" @click.prevent="toggleMenu">        
  <li v-if="this.action.includes('8')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Services </a>
  </li> 
</router-link>

<router-link to="/extrachargelist" data-widget="pushmenu" @click.prevent="toggleMenu">
  <li v-if="this.action.includes('9')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-list-alt"></i> Extra Charges </a> 
  </li>
</router-link>

<router-link to="/changepassword" data-widget="pushmenu" @click.prevent="toggleMenu">
  <li v-if="this.action.includes('10')" class="nav-item"> 
    <a class="nav-link"> 
    <i class="nav-icon fas fa-unlock-alt"></i> Change Password </a> 
  </li> 
</router-link>  


          <li class="nav-item"> <a class="nav-link pon" @click.prevent="logOut"> <i class="fas fa-sign-out-alt"></i> LogOut </a> </li>
      
        </ul>
  </mobile>
</div>    

      </nav>
    </div>
  </aside>
</div>
</template> 
<script>
import axios from "axios";
    export default {       
        data() {
            return {
                isActive: false,
                action:[],
                dd:'',
                company_id:this.$store.state.auth.user.data[0].company_id,   
                company_name:this.$store.state.auth.user.data[0].company_name,   
                app_type:this.$store.state.auth.user.data[0].app_type,        
                user_id: this.$store.state.auth.user.data[0].id,
                role_id:this.$store.state.auth.user.data[0].role_id, 
                message: 'Dashboard',                
                "Url": "https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg"   
            }
        },
        
        methods: {         
        async getAction() {
          var response = await axios.post(
            `http://laundry.ezeehost.com:4909/ClientIspmateApi/getroleeditscreen`, {
              company_id: this.company_id, 
              user_id: this.user_id,
              role_id: this.role_id         
        })      
              this.resources = response.data;               
              this.action1 = response.data.data[0].action_ids;               
              this.action = this.action1.split(","); 
              //console.log(this.action.includes('12'));           
              console.log(this.action)
        },  
        isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) 
          {
            return true
          } 
          else 
          {
            return false
          }
        },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted(){    
    this.getAction();    
  }
            
   };
</script>

<style>
.body { background: #F4F6F9 !important;}
.pon { padding-left: 7% !important; cursor:pointer;}
.nav-sidebar .nav-item > .nav-link {
    position: relative;
    border-bottom: 1px solid #eee;}
.table td, .table th {
    padding: 0.50rem !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.content-header {padding: 8px 0.5rem !important;}
.main-sidebar, .main-sidebar::before {
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    width: 215px;
}
.font-weight-light {
    font-weight: 600 !important;
}
.brand-link {  
    background: #4E12B9;
    text-align: center;
    font-weight: 900;
    color: #fff;
}
[class*="sidebar-dark-"] .sidebar a {
    color: #333 !important;
}
[class*="sidebar-dark"] .brand-link {
    border-bottom: 1
px
 solid #4E12B9 !important;
}
[class*="sidebar-dark-"] {
    background-color: #fff !important;
}
.navnew {padding: 10px 15px;}

</style>