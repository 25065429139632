<template>   
  <div></div>
</template>
<script>
    export default {
        data() {
            return {
                message: 'Dashboard',
                "Url": "https://cdn.fastly.picmonkey.com/contentful/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg"   
            }
        }
   };
</script>